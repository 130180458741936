<template>
  <v-select
    v-model="tipoImportacao"
    name="tipoImportacao"
    label="Tipo de Importação"
    :items="tiposImportacoesOrdenadas"
    item-value="id"
    item-text="descricao"
    :outlined="type === 'outlined'"
    class="mb-0 pb-0"
    no-data-text="Nenhum tipo de importação disponível"
    :loading="loading"
    @change="tipoImportacaoSelecionado"
  />
</template>

<script>

import TiposImportacoesDadosService from '@/services/TiposImportacoesDadosService';

export default {
  name: 'SelectTiposImportacoesDados',
  props: {
    type: {
      type: String,
      default: 'solo',
    },
    companyId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      tipoImportacao: '',
      tiposImportacoes: [],
      loading: false,
    };
  },
  watch: {
    companyId() {
      this.getTiposImportacoes(this.companyId);  
    }
  },
  methods: {
    getTiposImportacoes(companyId) {
      this.loading = true;
      TiposImportacoesDadosService
        .getTiposImportacoes(companyId)
        .then(response => this.tiposImportacoes = response.data)
        .finally(() => this.loading = false);
    },
    tipoImportacaoSelecionado(event) {
      const tipoImportacaoSelecionado = this.tiposImportacoesOrdenadas.find(
        tipoImportacao => tipoImportacao.id === event
      );
      this.$emit('tipoImportacaoSelecionado', tipoImportacaoSelecionado);
    }
  },
  computed: {
    tiposImportacoesOrdenadas() {
      return this.tiposImportacoes.sort((a, b) => (a.descricao < b.descricao) ? -1 : 1);
    }
  }
}
</script>
